@mixin imgsize(){
	width:325px;
	height:313px;
}
@import "static/bos/scss/breakpoint";
@import "var";
@import "slideCtrl";
#adSet{

}
#adSet{
	max-width:$mw;
	margin: 0 auto;
	padding-right:128px;
	position:relative;
	.set{
		height:60px;
	}
	.ctrl{
		position:absolute;
		right:0;
		top:0;
		width:128px;
		height:60px;
		background-color:#ffa900;
		padding:18px 0 0 22px;
		button{
			border:none;
			float:left;
			background: url(/static/ucms/img/main/adctrl.png) 0 0 no-repeat;
			width:25px;
			height:25px;
			&.adLeft{}
			&.adRight{background-position:right 0;}
			&.adStop{background-position:-30px 0;}
		}
	}
}
#adSet ul{}
#adSet ul li{float:left;margin-right:2px;width:216px;height:60px;background-color:#f5f5f5;}
#adSet ul li a{
	display:block;
	text-align:center;
	height:60px !important;
	filter: grayscale(100%);
	background-color:#f5f5f5;
	background-blend-mode: multiply;
	background-position:50% 50%;
	background-repeat:no-repeat;
}
#adSet ul li a img{max-height:60px;max-width:216px;
	-webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
	filter: grayscale(100%) brightness(120%);
	opacity:0.7;
	background-color: red;
	background-blend-mode: multiply;


}
a:focus{
	outline:dotted 1px black;
	outline-offset:0;
	.txt,
	.t{
		// outline:dotted 1px black;
	}
}
#mainContent{
	margin-top:40px;
	.topSet{
		max-width:$mw;
		@include breakpoint($tb){ 
			padding-left: 2%;
		}
		margin: 0 auto;
		@include clearfix;
	}
}
#notice{
	float:left;
	width:480px;
	@include breakpoint($tb){ 
		width: 48%;
		margin-right: 4%;
	}
	@include breakpoint($sm){ 
		width: 98%;
	}
	position:relative;
	>h2{
		font-family:'Nanum Square';
		line-height:1;
		padding:9px 0 20px;
		font-size:22px;
		font-weight:bold;
		border-bottom:1px solid #163d40;
	}
	>ul{
		>li{}
		a{
			@include clearfix;
			display:block;
			border-bottom:1px solid #d0d8d9;
			padding:15px 20px;
			font-size:15px;
			>span{}
			.txt{
				width:320px;
				@include breakpoint($tb){ width: 70%;}
				float:left;
				text-overflow:ellipsis;overflow:hidden;white-space:nowrap;
				&:before {
					content: '';
					width:4px;
					height:4px;
					display:inline-block;
					vertical-align:middle;
					margin-right:20px;
					background-color:#163d40;
				}
			}
			.date{float:right;}
		}
	}
	a.btnMore{
		width:40px;
		height:40px;
		position:absolute;
		right:0;
		top:0;
		background: url(/static/ucms/img/main/more.png) 0 0 no-repeat;
	}
}
#article{
	float:right;
	width:480px;
	@include breakpoint($tb){ width: 48%;}
	@include breakpoint($sm){ 
		width: 98%;
		float: left;
		margin-top: 20px;
	}
	position:relative;
	h2{
		font-family:'Nanum Square';
		line-height:1;
		padding:9px 0 20px;
		font-size:22px;
		font-weight:bold;
		border-bottom:1px solid #163d40;
	}
	a.link{
		margin-top:20px;
		display:block;
		@include clearfix;
		span{display:block;}
		.img{
			float:left;
			width:232px;
			border:1px solid #d0d8d9;
			@include breakpoint($tb){ width: 30%; border:none;}
			text-align:center;
			padding:9px 0;
			img{
				border:1px solid #d0d8d9;
				vertical-align:top;
				@include breakpoint($tb){ width: 100%; height: auto; }
			}
		}
		.s{
			float:right;
			width:226px;
			@include breakpoint($tb){ width: 67%;}
			font-size:15px;
			line-height:1.6;
			.title{
				font-size:20px;
				letter-spacing:-1px;
				font-weight:bold;
				color:#163d40;
				margin-bottom:8px;
			}
			.txt{
				letter-spacing:-0.5px;
				line-height:1.5;
			}
			.date{
				text-align:right;
			}
		}
	}
	a.btnMore{
		width:40px;
		height:40px;
		position:absolute;
		right:0;
		top:0;
		background: url(/static/ucms/img/main/more.png) 0 0 no-repeat;
	}
}

#service{
	margin-top:40px;
	height:340px;
	background: url(/static/ucms/img/main/serviceset.png) center 0 no-repeat;
	font-family:'Nanum Square';
	.set{
		max-width:$mw;
		margin: 0 auto;
		padding-left:520px;
		@include breakpoint($tb){
			padding-left: 20%;
			padding-right: 10%;
		}
	}
	h2{
		padding:100px 0 20px;
		line-height:1;
		font-size:38px;
		font-weight:bold;
		color:#163d40;
		position:relative;
		letter-spacing:-1px;
		&:after {
			content: '';
			position:absolute;
			width:60px;
			height:2px;
			background-color:#163d40;
			left:0;
			top:62px;
		}
	}
	p{
		font-size:20px;
		color:#242424;
		line-height:1.6;
		margin-bottom:40px;
	}
	a{
		border:1px solid #163d40;
		padding:10px 55px;
		display:inline-block;
		font-size:18px;
		color:#163d40;
		font-weight:bold;
		font-family:'Nanum Square';
		line-height:1;
	}
}
#quicklink{
	display:table;
	table-layout:fixed;
	width:$mw;
	@include breakpoint($tb){ 
		width: 100%;
		padding-left: 2%;
		padding-right: 2%;
	}
	margin:40px auto;
	>*{
		display:table-cell;
		vertical-align:top;
		@include breakpoint($sm){ 
			display: block;
			clear: both;
			margin-bottom: 20px;
		}
	}
	a{
		&:before {
			content: '';
			display:block;
			height:180px;
			background-position:center center;
			background-repeat:no-repeat;
			@include breakpoint($sm){ height: 80px; background-size: auto 100%; }
		}
		@for $i from 1 through 3 {
			&:nth-child(#{$i}):before{
				background-image:url('/static/ucms/img/main/bottom-img#{$i}.png');
			}
		}
		dl{
			font-family:'Nanum Square';
			line-height:1.6;
			text-align:center;
		}
		dt{
			font-size:22px;
			color:#163d40;
			line-height:1;
			padding:52px 0 12px;
			font-weight:bold;
			position:relative;
			&:after {
				content: '';
				position:absolute;
				width:60px;
				height:2px;
				background-color:#163d40;
				left:0;
				right:0;
				margin: 0 auto;
				top:30px;
			}

		}
		dd{
			font-size:15px;
		}
	}
}
#footer{
	margin-top:40px;
}